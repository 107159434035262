import { Box, IconButton, Stack, Typography } from "@mui/material"
import LinkedInIcon from "@mui/icons-material/LinkedIn"

export interface AboutProps {
  width: number
}

function Victor({ width }: AboutProps) {
  return (
    <Stack
      direction={width < 900 ? "column" : "row"}
      justifyContent="center"
      alignItems="center"
      spacing={5}
      sx={{ m: 2 }}
    >
      <Stack maxWidth="400px" alignItems="center">
        <Stack direction="row" spacing={5} sx={{ m: 2 }} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Victor - Logisticien
          </Typography>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              window.open(
                "https://www.linkedin.com/in/victor-eonnet-65036134/",
                "_blank"
              )
            }}
          >
            <LinkedInIcon fontSize="large" color="primary" />
          </IconButton>
        </Stack>
        <Typography>
          Pendant une décennie dans les entrepôts, il a identifié des problèmes
          de performance récurrents. Ses échanges avec de nombreux
          professionnels du secteur ont confirmé son expérience personnelle: il
          n’existe pas de solution satisfaisante à ces problèmes. Alors il a
          décidé d’en construire une, et elle s’appelle WM-up !
        </Typography>
      </Stack>
      <Box
        component="img"
        src="/victor.jpg"
        width="180px"
        sx={{ borderRadius: "50%" }}
      />
    </Stack>
  )
}

function Gilles({ width }: AboutProps) {
  return (
    <Stack
      direction={width < 900 ? "column" : "row"}
      justifyContent="center"
      alignItems={width < 900 ? "center" : "end"}
      spacing={5}
      sx={{ m: 2 }}
    >
      <Box
        component="img"
        src="/gilles.jpg"
        width="180px"
        sx={{ borderRadius: "50%" }}
      />
      <Stack maxWidth="400px">
        <Stack direction="row" spacing={5} sx={{ m: 2 }} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Gilles - Informaticien
          </Typography>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              window.open(
                "https://www.linkedin.com/in/gilles-desforges-4a54951/",
                "_blank"
              )
            }}
          >
            <LinkedInIcon fontSize="large" color="primary" />
          </IconButton>
        </Stack>
        <Typography>
          Gilles a fait l’essentiel de sa carrière dans deux startups à succès,
          en France et au Canada. Son savoir faire technique lui a permis de
          construire un produit qui apporte des solutions efficaces et
          innovantes aux problèmes identifiés par Victor.
        </Typography>
      </Stack>
    </Stack>
  )
}
export interface AboutProps {
  width: number
}
export default function About({ width }: AboutProps) {
  return (
    <Stack sx={{ m: 6 }} spacing={5} alignItems="center">
      <Typography variant="h6">
        WM-up est né de la rencontre entre Victor EONNET et Gilles DESFORGES.
      </Typography>

      <Victor width={width} />

      <Gilles width={width} />

      <a
        href="https://www.linkedin.com/company/wm-upp/"
        onClick={(e) => {
          e.preventDefault()
          window.open("https://www.linkedin.com/company/wm-upp/", "_blank")
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography fontSize="24px">Contactez nous sur LinkedIn</Typography>
          <LinkedInIcon fontSize="large" />
        </Stack>
      </a>
    </Stack>
  )
}
