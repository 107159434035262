import {
  Box,
  Button,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import Overview from "./Overview"
import Details from "./Details"
import Quote from "./Quote"
import About from "./About"

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#233252",
      },
    },
    typography: {
      allVariants: {
        color: "#233252",
      },
    },
  })

  const windowRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    setWidth(windowRef.current?.getBoundingClientRect().width ?? 0)
  }, [])
  useEffect(() => {
    const handler = () =>
      setWidth(windowRef.current?.getBoundingClientRect().width ?? 0)
    window.addEventListener("resize", handler, true)
    return () => {
      window.removeEventListener("resize", handler, true)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Stack
        ref={windowRef}
        textAlign="center"
        width="100vw"
        alignItems="center"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ m: 4 }}
          width="100%"
          maxWidth="1100px"
        >
          <Box component="img" src="/logo100x50.png" sx={{ ml: 3 }} />
          <Button
            variant="contained"
            sx={{ mr: 3 }}
            onClick={() =>
              window.open(
                "https://calendar.app.google/9dcR6mKhQMhb8UoU9",
                "_blank"
              )
            }
          >
            Prendre RDV
          </Button>
        </Stack>

        <Overview width={width} />

        <Details width={width} />

        <Quote />

        <About width={width} />

        <a href="https://www.freepik.com">
          <Typography fontSize={10}>Images by Freepik</Typography>
        </a>
      </Stack>
    </ThemeProvider>
  )
}

export default App
