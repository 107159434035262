import { Box, Stack, Typography } from "@mui/material"

export default function Quote() {
  return (
    <Box sx={{ position: "relative" }} width="100%">
      <Box
        component="img"
        src="/warehouse2.jpg"
        width="100%"
        height="300px"
        sx={{ overflow: "none" }}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "#225588aa",
        }}
      >
        <Typography
          color="white"
          variant="h4"
          maxWidth="800px"
          fontSize="20px"
          fontWeight="bold"
        >
          Vous affronterez les fluctuations de charge avec sérénité !
        </Typography>
      </Stack>
    </Box>
  )
}
