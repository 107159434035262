import { Box, Stack, Typography } from "@mui/material"

export interface OverviewProps {
  width: number
}

export default function Overview({ width }: OverviewProps) {
  return (
    <>
      <Stack
        direction={width < 900 ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        spacing={5}
        sx={{ m: 4, mt: 6 }}
      >
        <Stack alignItems="start" maxWidth="500px">
          <Typography variant="h2" fontWeight="bold" width="100%">
            Libérez
          </Typography>
          <Typography variant="h2" fontWeight="bold" width="100%">
            le potentiel
          </Typography>
          <Typography variant="h2" fontWeight="bold" width="100%">
            de vos entrepôts
          </Typography>
        </Stack>
        <Box
          component="img"
          src="/laptop.png"
          width="100%"
          maxWidth="400px"
          sx={{ borderRadius: 30 }}
        />
      </Stack>
      <Typography variant="h6" sx={{ m: 2, mb: 6 }}>
        Avec WM-up, optimisez les opérations de vos entrepôts grâce à la
        modélisation numérique.
      </Typography>
    </>
  )
}
